import { configureStore } from '@reduxjs/toolkit'
import slideOut from './slices/controllers/slide-out/slideOut'
import modal from './slices/controllers/modal/modal'
import media from './slices/media/mediaSlice'
import auth from './slices/auth/authSlice'
import secondSlideOut from './slices/second-slide-out/secondSlideOutSlice'

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import storeAdmin from './slices/store-admin/storeAdminSlice'
//eslint-disable-next-line
export type ApiBaseResponse = {}

// initialize an empty api service that we'll inject endpoints into later as needed
export const baseSplitApi = createApi({
  reducerPath: 'base',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.NEXT_PUBLIC_API_HOST}`
  }),
  tagTypes: [
    'Brand',
    'Category',
    'Delivery',
    'Service',
    'Tag',
    'StoreInfo',
    'product',
    'media',
    'discountCode',
    'Widgets',
    'StoreWidgets',
    'secondSlideOut'
  ],
  endpoints: () => ({})
})

export const store = configureStore({
  reducer: {
    // reducers
    [baseSplitApi.reducerPath]: baseSplitApi.reducer,
    // slices
    auth,
    slideOut,
    modal,
    storeAdmin,
    media,
    secondSlideOut
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(baseSplitApi.middleware)
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
