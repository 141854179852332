import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { type SecondSlideOutPayloadProps, type SecondSlideOutState } from './types'
import { RootState } from '../../store'

const initialState: SecondSlideOutState = {
  isHidden: true,
  title: '',
  componentId: 'default',
  footerId: 'default',
  extraArgs: null,
  isConstrained: false,
  drawerParams: {},
  isEditing: false
}

const secondSlideOutSlice = createSlice({
  name: 'secondSlideOut',
  initialState,
  reducers: {
    setSecondSlideOutChildren: (
      state,
      {
        payload: { title, componentId, footerId, extraArgs, drawerParams }
      }: PayloadAction<SecondSlideOutPayloadProps<any>>
    ) => {
      state.componentId = componentId
      state.footerId = footerId
      state.title = title
      state.extraArgs = extraArgs || null
      state.drawerParams = drawerParams as any
      state.isEditing = true
      return state
    },

    setOpenSecondSlideOut: function (state) {
      state.isHidden = false
    },

    setCloseSecondSlideOut: function (state) {
      state.isHidden = true
      state.isConstrained = false
    }
  }
})

// available actions - add more as needed, first they will have to be declared above
export const { setSecondSlideOutChildren, setOpenSecondSlideOut, setCloseSecondSlideOut } =
  secondSlideOutSlice.actions

// available selectors - add more as needed
export const selectSecondSlideOut = (state: RootState) => state.secondSlideOut

// exports slice reducer
export default secondSlideOutSlice.reducer
